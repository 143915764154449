import { FC, useEffect, useState } from 'react';
import {Dialog, DialogPanel, DialogTitle} from '@headlessui/react';

type IProps = {
  children: any;
  title?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  whiteHeader?: boolean;
  isOpen: boolean;
} & ({
  unclosable: true;
} | {
  unclosable?: false;
  onClose: () => void;
});

const Modal: FC<IProps> = (props) => {
  const setModalSizeClasses = () => {
    switch (props.size) {
      case 'sm':
        return 'lg:w-1/4 md:w-2/5 w-10/12';
      case 'md':
        return 'lg:w-1/3 md:w-3/5 w-10/12';
      case 'xl':
        return 'lg:w-3/4 w-10/12';
      case 'lg':
      default:
        return 'lg:w-1/2 md:w-4/5 w-10/12';
    }
  };

  const modalSize = setModalSizeClasses();

  const portal = (
    <Dialog as="div" className="relative z-9200" open={props.isOpen} onClose={() => !props.unclosable && props.onClose()}>
      <div className="fixed inset-0 z-9200 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className={`${modalSize} rounded-lg bg-white w-full transition duration-200 data-closed:scale-90 data-closed:opacity-0`}>
            <div
              className={
                props.whiteHeader
                  ? 'relative flex items-center justify-between p-4 border-b border-[#e9ecef]'
                  : 'relative flex items-center justify-between p-4 bg-primary'
              }
            >
              <DialogTitle as="h4"
                           className={`text-center mb-0 leading-normal grow ${props.whiteHeader ? 'text-black' : 'text-white'}`}>
                {props.title}
              </DialogTitle>
              { !props.unclosable && (
                <button
                  className="absolute top-2 right-2 cursor-pointer! p-4 flex items-center"
                  type="button"
                  onClick={() => props.onClose()}
                >
                  <i
                    className={
                      props.whiteHeader
                        ? 'text-black icon-cross'
                        : 'text-white icon-cross'
                    }
                  />
                </button>
              )}
            </div>
            <div className="p-4">
              {props.children}
            </div>
          </DialogPanel>
        </div>
      </div>
      <div className="bg-black opacity-50 z-9100 fixed inset-0"></div>
    </Dialog>
  );

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? portal : null;
};

export default Modal;
