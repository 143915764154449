import { FC, ReactNode } from 'react';

interface Props {
  active?: boolean;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const Chip: FC<Props> = (props) => (
  <div
    className={`
      ${props.className || ''}
      min-w-24 inline-flex items-center justify-center shrink-0 rounded-full border px-2.5 py-2 text-sm font-medium tracking-tight leading-4 gap-2 cursor-pointer hover:text-current/100 focus:outline-hidden focus-visible:ring-2 focus-visible:ring-white/75
      ${props.active ? 'border-secondary-light bg-primary/10 text-secondary' : 'border-greyscale-label text-greyscale-label'}
    `}
    onClick={() => {
      if (props.onClick) {
        props.onClick();
      }
    }}
  >
    {props.children}
  </div>
);

export default Chip;
