import { FC } from 'react';

interface Props<InputT> {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  labelClassName?: string;
  name?: string;
  onChange: (value: InputT) => void;
  size?: 'sm' | 'md' | 'lg';
  value: InputT;
  selectedValue: InputT;
  reverse?: boolean;
}

const Radio: FC<Props<any>> = (props) => {
  return (
    <div className={props.className}>
      <label
        className={`flex items-center m-0 gap-2 cursor-pointer ${
          props.reverse ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <input
          checked={props.value === props.selectedValue}
          className={[
            'form-radio rounded-full border-greyscale-separator text-primary shadow-xs focus:border-greyscale-separator focus:ring-3 focus:ring-offset-0 focus:ring-gray-200/50 disabled:text-gray-300 disabled:cursor-not-allowed disabled:border-gray-200 disabled:shadow-none',
            props.size === 'lg'
              ? 'h-6 w-6'
              : props.size === 'md'
              ? 'h-5 w-5'
              : 'h-4 w-4',
          ].join(' ')}
          disabled={props.disabled}
          name={props.name}
          type="radio"
          value={props.value}
          onChange={() => props.onChange(props.value)}
        />
        {!!props.label && (
          <span className={'flex-1 text-greyscale-label text-base font-medium tracking-tight ' + (props.labelClassName ?? '')}>
            {props.label}
          </span>
        )}
      </label>
      {!!props.errorMessage && (
        <span className="text-red-500 text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
};

export default Radio;
