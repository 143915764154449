import { ReactElement } from 'react';
import { Link } from '@/i18n/routing';

interface IconArgs {
  active?: boolean;
  className: string;
}

export interface Tab {
  active?: boolean;
  icon?: (args?: IconArgs) => ReactElement<any>;
  label: string;
  url: {
    href: string;
    replace?: boolean;
  };
}

interface Props {
  items: Tab[];
}

const Tabs = ({ items }: Props) => {
  return (
    <nav className="grid md:hidden grid-flow-col fixed inset-x-0 bottom-0 z-10 border border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
      {items.map((item, index) => (
        // @ts-expect-error
        <Link
          {...item.url}
          key={index}
          className={`
              flex flex-col items-center justify-center gap-1 py-3 no-underline
              ${
                item.active
                  ? 'text-primary'
                  : 'text-gray-600 dark:text-gray-300'
              }
            `}
        >
          {!!item.icon &&
            item.icon({
              className: `h-5 w-5 shrink-0 ${
                item.active ? 'text-primary-dark fill-primary' : ''
              }`,
            })}
          <span className="font-medium text-xs tracking-tight">
            {item.label}
          </span>
        </Link>
      ))}
    </nav>
  );
};

export default Tabs;
